import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SessionProvider } from "./components/useSessionTracker"; // Correct import
import Home from './components/Home';
import LanguageSelection from './components/LanguageSelection';
import CatorCustPage from './components/CatorCustPage'; 
import SubCategorySelection from './components/SubCategroySelection';
import CustomCategoryPage from './components/CustomCategoryPage'; // Import the new component
import CategorySelection from './components/CategorySelection';
import ModeSelection from './components/ModeSelection';
import RulesPage from './components/RulesPage';
import GamePage from './components/GamePage';
import LoginPage from './components/LoginPage';
import SignUpPage from './components/SignUpPage';
import ProfilePage from './components/ProfilePage';
import { auth } from './firebaseConfig';

const App = () => {
  const [user, setUser] = useState(null);

  // Function to handle setting user on login
  const handleLogin = (loggedInUser) => {
    setUser(loggedInUser);
  };

  // Function to handle logout
  const handleLogout = () => {
    auth.signOut();
    setUser(null);
  };

  // Track auth state to automatically set the user on app load if they are already logged in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return unsubscribe;
  }, []);

  return (
    <SessionProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home user={user} />} />
          <Route path="/language-selection" element={<LanguageSelection />} />
          <Route path="/catorcust/:language" element={<CatorCustPage />} /> {/* New Route */}
          <Route path="/category/:language" element={<CategorySelection />} />
          <Route path="/subcategory/:language/:category" element={<SubCategorySelection />} />
          <Route path="/custom-category/:language" element={<CustomCategoryPage />} />
          <Route path="/mode/:language/:category" element={<ModeSelection />} />
          <Route path="/mode-selection/custom/:category" element={<ModeSelection />} />
          <Route path="/rules/:language/:category/:mode" element={<RulesPage />} />
          <Route path="/game/:language/:category/:mode" element={<GamePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/profile" element={<ProfilePage user={user} />} />
        </Routes>
      </Router>
    </SessionProvider>
  );
};

export default App;
