import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSessionContext } from './useSessionTracker'; // Import the session tracker hook
import '../styles/App.css'; // Import the standardized CSS file

const ModeSelection = () => {
  const { language, category } = useParams(); // Get language and category from URL parameters
  const navigate = useNavigate(); // Navigation hook
  const { updateTraversal } = useSessionContext(); // Access updateTraversal function

  // Fallback for `language` and `category` to prevent errors
  const resolvedLanguage = language || 'Japanese'; // Default to 'Japanese'
  const resolvedCategory = category || 'General'; // Default to 'General'

  // Handler for when a mode is selected
  const handleModeClick = (mode) => {
    updateTraversal(`rules (${resolvedLanguage}-${resolvedCategory}-${mode}) ->`);
    navigate(`/rules/${resolvedLanguage}/${encodeURIComponent(resolvedCategory)}/${mode}`);
  };

  // Handle back navigation
  const goBack = () => {
    updateTraversal(`<- (${resolvedLanguage}) category`);
    navigate(`/category/${resolvedLanguage}`);
  };

  return (
    <div className="page-container">
      {/* Arrow button for navigating back */}
      <button className="arrow-button" onClick={goBack}>
        ←
      </button>

      <h1 className="heading">
        Select a Mode to Learn in{' '}
        {resolvedCategory.charAt(0).toUpperCase() + resolvedCategory.slice(1)}
      </h1>

      <div className="button-group">
        {/* Learn in English button */}
        <button
          className="button"
          onClick={() => handleModeClick('english')}
        >
          Learn in English
        </button>

        {/* Learn in Original Language button */}
        <button
          className="button"
          onClick={() => handleModeClick('original')}
        >
          Learn in {resolvedLanguage.charAt(0).toUpperCase() + resolvedLanguage.slice(1)}
        </button>
      </div>
    </div>
  );
};

export default ModeSelection;
