import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  query,
  where,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/App.css";

const CustomCategoryPage = () => {
  const [categoryName, setCategoryName] = useState("");
  const [wordPairs, setWordPairs] = useState([{ original: "", translation: "" }]);
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [customCategories, setCustomCategories] = useState({});
  const [selectedSet, setSelectedSet] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();
  const { language } = useParams();

  const formRef = useRef(null);

  useEffect(() => {
    if (!currentUser || !language) return;

    const unsubscribe = onSnapshot(
      query(
        collection(db, "users", currentUser.uid, "customSets"),
        where("language", "==", language.toLowerCase())
      ),
      (snapshot) => {
        const fetchedCategories = {};
        snapshot.forEach((doc) => {
          const data = doc.data();
          fetchedCategories[doc.id] = { ...data, id: doc.id };
        });
        setCustomCategories(fetchedCategories);
      }
    );

    return () => unsubscribe();
  }, [currentUser, language]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsBoxOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleWordChange = (index, field, value) => {
    const updatedPairs = [...wordPairs];
    updatedPairs[index][field] = value;
    setWordPairs(updatedPairs);
  };

  const addWordPair = () => {
    setWordPairs([...wordPairs, { original: "", translation: "" }]);
  };

  const removeWordPair = (index) => {
    const updatedPairs = wordPairs.filter((_, i) => i !== index);
    setWordPairs(updatedPairs);
  };

  const handleSaveCategory = async () => {
    if (!currentUser || !language) {
      alert("User is not authenticated or language is not selected!");
      return;
    }

    if (!categoryName || wordPairs.some((pair) => !pair.original || !pair.translation)) {
      alert("Please fill in all fields before saving.");
      return;
    }

    try {
      const customSet = {
        setName: categoryName,
        wordPairs,
        language: language.toLowerCase(),
      };

      await addDoc(collection(db, "users", currentUser.uid, "customSets"), customSet);

      alert("Custom set saved successfully!");
      setIsBoxOpen(false);
      setCategoryName("");
      setWordPairs([{ original: "", translation: "" }]);
    } catch (error) {
      console.error("Error saving custom set:", error);
      alert("An error occurred while saving. Please try again.");
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteDoc(doc(db, "users", currentUser.uid, "customSets", id));
      alert("Custom set deleted successfully!");
    } catch (error) {
      console.error("Error deleting custom set:", error);
      alert("An error occurred while deleting the custom set. Please try again.");
    }
  };

  const handleViewSet = (id) => {
    const selectedCategory = customCategories[id];
    setSelectedSet(selectedCategory);
    setWordPairs(selectedCategory.wordPairs); // Populate wordPairs for display
    setIsEditing(false);
  };

  const handleEditSet = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    if (!selectedSet) return;
    try {
      const updatedSet = {
        ...selectedSet,
        wordPairs,
      };
      await updateDoc(doc(db, "users", currentUser.uid, "customSets", selectedSet.id), updatedSet);
      alert("Set updated successfully!");
      setSelectedSet(null);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating set:", error);
      alert("An error occurred while updating the set. Please try again.");
    }
  };

  const handlePlayCategory = (id) => {
    const selectedCategory = customCategories[id];
    navigate(`/mode/${language}/${encodeURIComponent(selectedCategory.setName)}`);
  };

  return (
    <div className="page-container">
      {/* Back button */}
      <button className="arrow-button" onClick={() => navigate(`/catorcust/${language}`)}>
        ←
      </button>

      <h1 className="heading">Custom Categories for {language}</h1>

      <div className="categories-grid">
        {Object.keys(customCategories).length > 0 ? (
          Object.entries(customCategories).map(([id, details]) => (
            <div key={id} className="category-row">
              <div className="set-name">{details.setName}</div>
              <div className="button-group">
                <button className="button" onClick={() => handleViewSet(id)}>
                  View
                </button>
                <button className="button" onClick={() => handleDeleteCategory(id)}>
                  Delete
                </button>
                <button className="button" onClick={() => handlePlayCategory(id)}>
                  Play
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No custom categories yet.</p>
        )}
      </div>

      {selectedSet && (
        <div className="word-pairs-container visible">
          <h2>{selectedSet.setName}</h2>
          <ul>
            {wordPairs.map((pair, index) => (
              <li key={index}>
                {isEditing ? (
                  <div className="word-pair">
                    <input
                      className="word-input"
                      type="text"
                      value={pair.original}
                      onChange={(e) => handleWordChange(index, "original", e.target.value)}
                    />
                    <input
                      className="translation-input"
                      type="text"
                      value={pair.translation}
                      onChange={(e) => handleWordChange(index, "translation", e.target.value)}
                    />
                    {index > 0 && (
                      <button
                        className="button remove-word-pair"
                        onClick={() => removeWordPair(index)}
                      >
                        ✕
                      </button>
                    )}
                  </div>
                ) : (
                  <span>
                    {pair.original} - {pair.translation}
                  </span>
                )}
              </li>
            ))}
          </ul>
          {isEditing ? (
            <div className="buttons-row">
              <button onClick={addWordPair} className="button">
                Add Word Pair
              </button>
              <button onClick={handleSaveEdit} className="button">
                Save Changes
              </button>
            </div>
          ) : (
            <div className="buttons-row">
              <button onClick={handleEditSet} className="button">
                Edit Set
              </button>
              <button onClick={() => setSelectedSet(null)} className="button">
                Close
              </button>
            </div>
          )}
        </div>
      )}

      {isBoxOpen && (
        <div className="add-set-form-expanded" ref={formRef}>
          <h2 className="box-title">Create New Set</h2>
          <label className="form-label">
            Set Name:
            <input
              className="set-name-input"
              type="text"
              value={categoryName}
              onChange={handleCategoryNameChange}
              placeholder="Enter set name"
            />
          </label>

          <h3 className="form-label">Word Pair</h3>
          <div className="word-pairs-container">
            {wordPairs.map((pair, index) => (
              <div key={index} className="word-pair">
                <input
                  className="word-input"
                  type="text"
                  value={pair.original}
                  onChange={(e) => handleWordChange(index, "original", e.target.value)}
                  placeholder="Word"
                />
                <input
                  className="translation-input"
                  type="text"
                  value={pair.translation}
                  onChange={(e) =>
                    handleWordChange(index, "translation", e.target.value)
                  }
                  placeholder="Translation"
                />
                {index > 0 && (
                  <button
                    className="button remove-word-pair"
                    onClick={() => removeWordPair(index)}
                  >
                    ✕
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="buttons-row">
            <button className="button" onClick={addWordPair}>
              Add Word Pair
            </button>
            <button className="button" onClick={handleSaveCategory}>
              Save Set
            </button>
          </div>
        </div>
      )}

      <button
        className={`floating-add-button ${isBoxOpen ? "active" : ""}`}
        onClick={() => {
          setIsBoxOpen(true);
          setCategoryName("");
          setWordPairs([{ original: "", translation: "" }]); // Ensure form is empty
        }}
      >
        +
      </button>
    </div>
  );
};

export default CustomCategoryPage;
