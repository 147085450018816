import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/App.css'; // Import standardized styles

const CatorCustPage = () => {
  const { language } = useParams(); // Get the selected language from URL params
  const navigate = useNavigate();

  // Handlers for navigation
  const handleStandardCategories = () => {
    navigate(`/category/${language}`);
  };

  const handleCustomCategories = () => {
    navigate(`/custom-category/${language}`); // Navigate to the custom category page with the language as a parameter
  };

  // Back navigation
  const goBack = () => {
    navigate('/language-selection');
  };

  return (
    <div className="page-container">
      {/* Back button */}
      <button className="arrow-button" onClick={goBack}>
        ←
      </button>
      <h1 className="heading">
        {language.charAt(0).toUpperCase() + language.slice(1)} Learning Mode
      </h1>
      <div className="button-group">
        <button onClick={handleStandardCategories} className="button">
          Standard Categories
        </button>
        <button onClick={handleCustomCategories} className="button">
          Custom Categories
        </button>
      </div>
    </div>
  );
};

export default CatorCustPage;
