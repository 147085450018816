import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { storage, ref, getDownloadURL } from '../firebaseConfig';
import '../styles/App.css';

const SubCategorySelection = () => {
  const { language, category } = useParams(); // Get language and category from URL params
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch subcategories from Firebase Storage
    const fetchSubCategories = async () => {
      try {
        const filePath = `assets/vocabularies/${language}.json`;
        const fileRef = ref(storage, filePath);
        const fileURL = await getDownloadURL(fileRef);

        const response = await fetch(fileURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setSubCategories(Object.keys(data[category])); // Fetch subcategories under "Alphabet"
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    fetchSubCategories();
  }, [language, category]);

  const handleSubCategoryClick = (subCategory) => {
    navigate(`/mode/${language}/${encodeURIComponent(subCategory)}`);
  };

  const goBack = () => {
    navigate(`/category/${language}`);
  };

  return (
    <div className="page-container">
      <button className="arrow-button" onClick={goBack}>
        ←
      </button>
      <h1 className="heading">{category}: Select Subcategory</h1>
      <div className="button-group">
        {subCategories.map((subCategory, index) => (
          <button
            key={index}
            className="button"
            onClick={() => handleSubCategoryClick(subCategory)}
          >
            {subCategory}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SubCategorySelection;
